import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "@emotion/styled"

const Article = styled.article`
  position: relative;
  padding: 1rem;
`

const SecondPage = () => (
  <Layout>
    <Article>
      <SEO title="Contact" />
      <h1>Contact</h1>
      <p>
        <a href="mailto:me@jozzhart.com">me@jozzhart.com</a>
      </p>
    </Article>
  </Layout>
)

export default SecondPage
